/* You can add global styles to this file, and also import other style files */
// Import Bootstrap functions and variables

@import "primeng/resources/primeng.css";

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

@import "theme.scss";

@import "bootstrap/scss/bootstrap";
@import "@fortawesome/fontawesome-free/css/all.min.css";

@font-face {
  font-family: "AppFont";
  src: url("/fonts/open-sans.regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeue-Roman";
  src: url("/fonts/HelveticaNeue-Roman.otf") format("opentype");
}

@font-face {
  font-family: "NeueHaasGroteskTextPro-Thin";
  src: url("/fonts/NHaasGroteskTXPro-Rg.ttf") format("truetype");
}

@include color-mode(dark) {
  --bs-body-bg: #121212 !important;

  .bg-body-tertiary {
    background-color: #181818 !important;
    /* Replace with your desired color */
  }
}

.btn-check {
  background-color: #86d9f0 !important;
}

.form-check-input:checked {
  background-color: #86d9f0 !important;
  border-color: #86d9f0 !important;
}

.nav-link-light.active {
  --bs-nav-pills-link-active-bg: #86d9f0;
  color: #121212 !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #121212;
  color: #fcfcfc;
}

input:focus,
textarea:focus,
select:focus {
  border-color: #121212 !important;
  box-shadow: 0 0 0 0.25rem rgba(134, 217, 240, 0.25) !important;
}

.input-no-focus {
  &:focus {
    box-shadow: none !important;
  }
}

html,
body {
  height: 100%;
  font-family: "AppFont", sans-serif !important;
}

.markerVessel {
  background-size: cover;
  height: 60px !important;
  width: 46.98px !important;
  cursor: pointer;

  &-owned {
    background-image: url("/icons/vessel-owned.svg");
  }

  &-favorite {
    background-image: url("/icons/vessel-favorite.svg");
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    border-radius: 20px;
    width: 38px;
    height: 37px;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 20px 25px -5px, rgba(255, 255, 255, 0.04) 0px 10px 10px -5px;
    background-color: #ffffff9f;
    color: var(--primary);
    font-size: larger;
  }

  &-clustered {
    background-image: url("/icons/vessel-owned-clustered.svg");
    padding: 4px 4px;

    &-favorite {
      background-image: url("/icons/vessel-favorite-clustered.svg");

      .markerVessel-content {
        background-color: #7171719f;
        color: white;
      }
    }
  }
}

.markerVesselFavorite {
  background-image: url("/icons/vessel-favorite.svg");

  &-clustered {
    background-image: url("/icons/vessel-favorite-clustered.svg");
    padding: 15px 20px;
  }
}

.markerVehicle {
  background-image: url("/icons/vehicle.png");
  background-size: cover;
  width: 75px !important;
  height: 75px !important;
  cursor: pointer;
}

.markerStationary {
  background-image: url("/icons/stationary.png");
  background-size: cover;
  width: 75px !important;
  height: 75px !important;
  cursor: pointer;
}

.bi {
  vertical-align: -5px;
  fill: currentColor;

  &-center {
    vertical-align: -0.125em;
  }
}

.component-header--filter-dropdown .dropdown-menu.show {
  max-height: unset !important;
}

.dropdown-menu.show {
  max-height: 400px !important;
  overflow-y: auto !important;
}

.dropdown-large .dropdown-menu.show {
  max-height: 800px !important;
}

.dropdown-small .dropdown-menu.show {
  max-height: 120px !important;
}

.modal {
  z-index: 9999;
}

.tooltip-inner {
  border: 1px solid var(--tooltip-text-color);
  background-color: var(--tooltip-background-color);
  color: var(--tooltip-text-color);
  padding: 2px 5px;
  border-radius: 0;
  overflow: hidden;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-width: 0 0 1px 0;
}

.p-paginator .p-paginator-current,
.p-paginator button {
  border: 0 none;
}

.p-paginator .p-paginator-current,
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  color: var(--pagination-color);
}

.p-datatable .p-sortable-column.p-highlight,
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon,
.p-paginator .p-paginator-pages .p-paginator-page {
  color: var(--highlight-color);
}

.p-paginator .p-paginator-pages .p-paginator-page {
  color: var(--pagination-color);
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: var(--pagination-highlight-background-color);
  border-color: var(--pagination-highlight-background-color);
  color: var(--pagination-highlight-color);
}

.fleet-button-popup {
  background-color: var(--button-popup-background-color);
  color: var(--button-popup-text-color);

  &:hover {
    background-color: var(--button-popup-text-color);
    color: var(--button-popup-text-color-hover);
  }
}

.selectable-card-body {
  border: 3px solid #ffffff00;

  -webkit-transition: border 500ms ease-out;
  -moz-transition: border 500ms ease-out;
  -o-transition: border 500ms ease-out;
  transition: border 500ms ease-out;

  &.selected-card {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-color: #86d9f0;
    border-radius: 3px;
  }

  &:hover {
    background-color: var(--background);
  }
}

.p-overlay-content .p-dropdown-items {
  padding-left: 0px;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 10px;
}

.drawing-mode .leaflet-interactive {
  pointer-events: none;
}

.map-measuring--tooltip {
  background-color: var(--background-trans-60);
  color: var(--color);
  padding: 4px;
  border: none;

  &::before {
    border-top-color: var(--background-trans-60);
  }
}

.map-measuring {
  .app-color-picker .preview {
    padding: 2px !important;
  }
}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive,
svg.leaflet-image-layer.leaflet-interactive path {
  &:focus {
    outline: none;
  }
}

.map-alert-tooltip {
  background-color: var(--background);
  box-shadow: none;
  border: none;
  color: var( --danger);
  font-size: 12px;
  font-weight: bold;

  &:before {
    border-top-color: transparent;
  }
}



.marker-vesselName-tooltip {
  background-color: var(--background-trans-60);
  box-shadow: var(--map-popup-shadow-box);
  border: none;
  color: var(--color);
  font-size: 12px;
  font-weight: bold;
  text-align: center;

  &:before {
    border-top-color: transparent;
  }
}

.fontawesome-icon {
  font-size: 22px;
  &-small {
    font-size: 18px;
  }
}

.status-icon {
  font-size: 22px;
  &-small {
    font-size: 18px;
  }
}

.color-normal {
  color: var(--success);
}
.color-warning {
  color: var(--warning);
}
.color-danger {
  color: var(--danger);
}

.fill-normal {
  fill: var(--success);
}
.fill-warning {
  fill: var(--warning);
}
.fill-danger {
  fill: var(--danger);
}

.bg-normal {
  background-color: var(--success);
}
.bg-warning {
  background-color: var(--warning);
}
.bg-danger {
  background-color: var(--danger);
}

.form-check-input,
.clickable {
  cursor: pointer;
}

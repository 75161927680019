// Override Bootstrap's primary color
$primary: #121212;
$secondary: #717171;
$success: #2a8e60;
$warning: #ec8e2f;
$danger: #e1234a;
$light: #fcfcfc;

$theme-colors: map-merge(
  $theme-colors,
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "warning": $warning,
    "light": $light,
    "danger": $danger,
  )
);

:root {
  --login-shadow-box: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
  --map-popup-shadow-box: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  --tooltip-background-color: #{$light};
  --tooltip-text-color: #414141;

  --button-popup-background-color: unset;
  --button-popup-text-color: #414141;
  --button-popup-text-color-hover: #{$light};

  --highlight-color: #86d9f0;
  --highlight-background-color-semitrans: #86d9f0cc;
  --pagination-color: #{$primary};
  --pagination-highlight-background-color: #86d9f0;
  --pagination-highlight-color: #{$primary};

  --background: #{$light};
  --background-semitrans: #fcfcfcdd;
  --background-trans-60: #fcfcfc99;
  --color-reverse: #{$light};
  --color: #{$primary};

  --popup-background-color: #dfdfdf;
  --popup-text-color: #{$primary};

  --footer-background-color: #414141cc;
  --footer-text-color: #{$light};

  --chart-background-color: #{$light};
  --chart-text-color: #{$primary};

  --primary: #{$primary};
  --primary-semitrans: #121212cc;
  --secondary: #{$secondary};
  --success: #{$success};
  --warning: #{$warning};
  --danger: #{$danger};
  --danger-semitrans: #e1234add;
  --danger-darker: #a81331;
  --danger-lighter: #e03f60;
  --light: #{$light};

  --light-gray: #f0f0f0;
  --border-light: #{$primary};
}

[data-bs-theme="dark"] {
  --highlight-color: #86d9f0;
  --pagination-color: #{$light};
  --pagination-highlight-background-color: #86d9f0;
  --pagination-highlight-color: #{$primary};

  --login-shadow-box: 0px 10px 34px -15px rgba(255, 255, 255, 0.24);
  --tooltip-background-color: #414141;
  --tooltip-text-color: #{$light};

  --button-popup-background-color: unset;
  --button-popup-text-color: #{$light};
  --button-popup-text-color-hover: #414141;

  --background: #2d2d2d;
  --background-semitrans: #2d2d2ddd;
  --background-trans-60: #2d2d2d99;
  --color-reverse: #{$primary};
  --color: #{$light};

  --popup-background-color: #414141;
  --popup-text-color: #{$light};

  --footer-background-color: var(--primary-semitrans);
  --footer-text-color: #{$light};

  --chart-background-color: #{$light};
  --chart-text-color: #{$primary};

  --chart-background-color: #2d2d2d;
  --chart-text-color: #{$light};

  --light-gray: #{$secondary};
  --border-light: #f0f0f0;
}
